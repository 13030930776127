import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import { authMiddleware, titleMiddleware } from '@/middleware'

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter)
}

/**
 * A little explanation for this module.
 *
 * Besides the default route properties, we're introducing a new one called
 * `meta`. It consists of route metadata. For now it includes the `auth`
 * property, which defaults to true, and if set to false, the route will not
 * require authentication. The other property is called `title`, it defaults
 * to `Intranet IMFD` and can be changed to the document title for that route.
 *
 * Note that anything can be defined in this meta property, but what defines if
 * these properties are used or not is _middleware_. Middleware is just a
 * regular javascript function that can be passed to router.beforeEach. In
 * this sense, this middleware is currently before-only, not after-only. But
 * that's enough for these cases.
 *
 * We define each middleware (which is a function that takes 3 arguments) in
 * the `middleware/` module. Each own in it's own file and then we export them
 * all in the module index. Please follow that convention.
 *
 * In this middleware, if you're gonna use the `meta` property (you don't have
 * to, by the way), you're advised to extend the defaultMeta object with the
 * `meta` attribute from each route. See authMiddleware or titleMiddleware for
 * an example.
 */

export const defaultMeta = {
  auth: true,
  title: 'Intranet IMFD'
}

// TODO: link to TheHome
// component: TheHome

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/divisions/1'
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/PersonProfile.vue'),
    meta: {
      title: 'Profile | Intranet IMFD'
    }
  },
  {
    path: '/signup/:token',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue'),
    meta: {
      auth: false,
      title: 'Sign up | Intranet IMFD'
    }
  },
  {
    path: '/divisions/:id(\\d+)/new-division',
    name: 'divisionCreate',
    component: () =>
      import(/* webpackChunkName: "division-create" */ '../views/DivisionCreate.vue'),
    meta: {
      title: 'Create Division | Intranet IMFD'
    }
  },
  {
    path: '/divisions/:id(\\d+)/new-project',
    name: 'projectCreate',
    component: () => import(/* webpackChunkName: "division-create" */ '../views/ProjectCreate.vue'),
    meta: {
      title: 'Create Project | Intranet IMFD'
    }
  },
  {
    path: '/divisions/:id(\\d+)/invite',
    name: 'membershipCreate',
    component: () =>
      import(/* webpackChunkName: "division-create" */ '../views/MembershipCreate.vue'),
    meta: {
      title: 'Invite New Member | Intranet IMFD'
    }
  },
  {
    path: '/divisions/:id(\\d+)',
    name: 'division',
    component: () =>
      import(/* webpackChunkName: "division-detail" */ '../views/DivisionDetail.vue'),
    meta: {
      title: 'Division | Intranet IMFD'
    }
  },
  {
    path: '/divisions/:divisionId(\\d+)/members/:memberId(\\d+)',
    name: 'editMembership',
    component: () =>
      import(/* webpackChunkName: "edit-membership" */ '../views/EditMembership.vue'),
    meta: {
      title: 'Edit Member | Intranet IMFD'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginPage.vue'),
    meta: {
      auth: false,
      title: 'Login | Intranet IMFD'
    }
  },
  {
    path: '/members/:id(\\d+)',
    name: 'memberProfile',
    component: () => import(/* webpackChunkName: "division-create" */ '../views/MemberProfile.vue'),
    meta: {
      auth: false,
      title: 'Member Profile | Intranet IMFD'
    }
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "signup" */ '../views/ResetPassword.vue'),
    meta: {
      auth: false,
      title: 'Reset password | Intranet IMFD'
    }
  },
  {
    path: '/control-panel',
    name: 'controlPanel',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Permissions.vue'),
    meta: {
      auth: true,
      title: 'Control Panel | Intranet IMFD'
    }
  },
  {
    path: '/person-create',
    name: 'personCreate',
    component: () => import(/* webpackChunkName: "personCreate" */ '../views/PersonCreate.vue'),
    meta: {
      auth: true,
      title: 'Person Create | Intranet IMFD'
    }
  },
  {
    path: '/publication-create',
    name: 'publicationCreate',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../components/PublicationCreate.vue'),
    meta: {
      auth: true,
      title: 'Publication Create | Intranet IMFD'
    }
  },
  {
    // Leave this path at the very end
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/404.vue'),
    meta: {
      auth: false,
      title: 'Not Found | Intranet IMFD'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(authMiddleware(store))
router.beforeEach(titleMiddleware)

export default router
