import Vue from 'vue'
import { z, ZodError } from 'zod'
import i18n from '@/i18n'
import { dateWithoutOffset } from '@/utils/date-utils'

const projectInformationSchema = z
  .object({
    name: z.string(),
    short_name: z.string(),
    description: z.string(),
    start_date: z.date(),
    end_date: z.date().optional()
  })
  .refine(
    (data) => {
      if (!data.start_date || !data.end_date) {
        return true
      }
      return data.start_date < data.end_date
    },
    {
      message: i18n.t('form.endDate'),
      path: ['end_date']
    }
  )

const projectAdminstratorSchema = z
  .object({
    person_id: z.string().or(z.number()).optional() | null,
    role_type_id: z.number().optional(),
    membership_type_id: z.number().optional()
  })
  .superRefine((val, ctx) => {
    if (typeof val.person_id === 'string' && !val.membership_type_id) {
      ctx.addIssue({
        message: i18n.t('form.fieldRequired'),
        path: ['membership_type_id'],
        code: z.ZodIssueCode.custom
      })
    }
    if (typeof val.person_id === 'number' && !val.role_type_id) {
      ctx.addIssue({
        message: i18n.t('form.fieldRequired'),
        path: ['role_type_id'],
        code: z.ZodIssueCode.custom
      })
    }
  })

const companyPartner = z.object({
  name: z.string().optional(),
  country: z.string().optional(),
  city: z.string().optional(),
  companyType: z.string().optional()
})

const projectExtraInfo = z.object({
  milenioFund: z.string().optional(),
  otherFund: z.string().optional(),
  projectLink: z.string().url().optional(),
  activityType: z.string().optional(),
  companyPartner: companyPartner.optional()
})

const getDefaultState = () => {
  return {
    activeStep: 0,
    projectInformation: {},
    adminInformation: {},
    extraInformation: { partner_company: {} },
    errors: {},
    types: {}
  }
}

const steps = {
  PROJECT_STEP: 0,
  ADMIN_STEP: 1,
  EXTRA_INFO: 2
}

// initial state
const state = getDefaultState()

export const mutations = {
  nextStep(state, { value }) {
    state.activeStep = value
  },
  updateInformationValue(state, { field, value }) {
    Vue.set(state.projectInformation, field, value)
    Vue.delete(state.types, field)
    Vue.delete(state.errors, field)
  },
  updateAdministratorValue(state, { field, value }) {
    Vue.set(state.adminInformation, field, value)
    Vue.delete(state.types, field)
    Vue.delete(state.errors, field)
  },
  updateExtraInfoValue(state, { field, subfield, value }) {
    if (subfield) {
      Vue.set(state.extraInformation['partner_company'], subfield, value)
    } else {
      Vue.set(state.extraInformation, field, value)
    }
    Vue.delete(state.types, field)
    Vue.delete(state.errors, field)
  },
  resetStore(state) {
    Object.assign(state, getDefaultState())
  },
  addErrors(state, { errors }) {
    errors.map((field) => {
      Vue.set(state.types, field, 'is-danger')
      Vue.set(state.errors, field, i18n.t('form.fieldRequired'))
    })
  },
  validateForm(state) {
    try {
      switch (state.activeStep) {
        case steps.PROJECT_STEP:
          projectInformationSchema.parse(state.projectInformation)
          break

        case steps.ADMIN_STEP:
          projectAdminstratorSchema.parse(state.adminInformation)
          break

        case steps.EXTRA_INFO:
          projectExtraInfo.parse(state.extraInformation)
          break
      }
    } catch (error) {
      if (error instanceof ZodError) {
        error.issues.map((err) => {
          Vue.set(state.types, err.path[0], 'is-danger')
          Vue.set(
            state.errors,
            err.path[0],
            err.message === 'Required' ? i18n.t('form.fieldRequired') : err.message
          )
        })
      }
    }
  },
  onValidationError(state, { error }) {
    const errorDetail = error.report?.detail || []
    if (Array.isArray(errorDetail)) {
      errorDetail.forEach((error) => {
        const field = error.loc[error.loc.length - 1]
        Vue.set(state.types, field, 'is-danger')
        Vue.set(state.errors, field, error.msg)
      })
    }
  }
}

export const actions = {
  nextStep: ({ commit }) => {
    commit('nextStep')
  },
  setInitialInfo: ({ commit }, { division }) => {
    commit('updateInformationValue', {
      field: 'name',
      value: division.name
    })
    commit('updateInformationValue', {
      field: 'short_name',
      value: division.short_name
    })
    commit('updateInformationValue', {
      field: 'description',
      value: division.description
    })
    commit('updateInformationValue', {
      field: 'start_date',
      value: dateWithoutOffset(division.start_date)
    })
    if (division.end_date) {
      commit('updateInformationValue', {
        field: 'end_date',
        value: dateWithoutOffset(division.end_date)
      })
    }
    if (division.milenio_funds) {
      commit('updateExtraInfoValue', {
        field: 'milenio_funds',
        value: division.milenio_funds
      })
    }
    if (division.other_funds) {
      commit('updateExtraInfoValue', {
        field: 'other_funds',
        value: division.other_funds
      })
    }
    if (division.link) {
      commit('updateExtraInfoValue', {
        field: 'link',
        value: division.link
      })
    }
    if (division.activity_type_id) {
      commit('updateExtraInfoValue', {
        field: 'activity_type_id',
        value: division.activity_type_id
      })
    }
    if (division.partner_company[0]) {
      if (division.partner_company[0].name) {
        commit('updateExtraInfoValue', {
          field: 'companyPartnerName',
          subfield: 'name',
          value: division.partner_company[0].name
        })
      }
      if (division.partner_company[0].country) {
        commit('updateExtraInfoValue', {
          field: 'companyPartnerCountry',
          subfield: 'country',
          value: division.partner_company[0].country
        })
      }
      if (division.partner_company[0].city) {
        commit('updateExtraInfoValue', {
          field: 'companyPartnerCity',
          subfield: 'city',
          value: division.partner_company[0].city
        })
      }
      if (division.partner_company[0].activity_type_id) {
        commit('updateExtraInfoValue', {
          field: 'companyPartnerActivityType',
          subfield: 'activity_type_id',
          value: division.partner_company[0].activity_type_id
        })
      }
    }
  }
}

export default { state, mutations, actions, namespaced: true }
