import axios from './index'
import FormValidationError from '@/errors/FormValidationError'
import NotAllowedError from '@/errors/NotAllowedError'
import AppError from '@/errors/AppError'

export const findDivision = async ({ divisionId }) => {
  const requestUrl = `api/divisions/${divisionId}`
  const response = await axios.get(requestUrl)

  return response.data
}

export const findMembers = async ({ divisionId }) => {
  const response = await axios.get(`api/divisions/${divisionId}/tree-members`)

  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export const findAllDivisions = async () => {
  const response = await axios.get('/api/divisions')

  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export const findSubdivisions = async ({ divisionId }) => {
  const response = await axios.get(`api/divisions/${divisionId}/subdivisions`)

  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export const findProjects = async ({ divisionId }) => {
  const response = await axios.get(`api/divisions/${divisionId}/tree-projects`)

  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export const findTree = async () => {
  const response = await axios.get(`/structure/divisions/tree/`)

  return response.data
}

export const findRoot = async () => {
  const response = await axios.get(`api/divisions/root`)

  return response.data
}

export const createDivision = async ({ parent, ...division }) => {
  if (typeof division.person_id !== 'number') {
    division.person_email = division.person_id
    delete division.person_id
  }
  const response = await axios.post(`api/divisions/${parent}/subdivisions`, division)

  if (response.status === 201) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else if (response.status === 403) {
    throw new NotAllowedError(response.data)
  }

  // just throw an unmanaged exception here. maybe it can be caught by sentry
  throw new Error('Unexpected status code returned')
}

export const createProject = async ({ parent, ...division }) => {
  if (typeof division.person_id !== 'number') {
    division.person_email = division.person_id
    delete division.person_id
  }
  const response = await axios.post(`api/divisions/${parent}/projects`, division)

  if (response.status === 201) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  }

  throw new Error('Unexpected status code returned')
}

export const deleteMemberRole = async ({ divisionId, memberId }) => {
  const response = await axios.delete(`api/divisions/${divisionId}/members/${memberId}`)
  if (response.status === 204) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export const getMemberRole = async ({ divisionId, memberId }) => {
  const response = await axios.get(`api/divisions/${divisionId}/members/${memberId}`)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export const editDivision = async (division) => {
  const response = await axios.patch(`api/divisions/${division.id}`, division)

  if (response.status === 201) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  }

  throw new Error('Unexpected status code returned')
}

export const deleteDivision = async ({ divisionId }) => {
  const response = await axios.delete(`api/divisions/${divisionId}`)

  if (response.status === 204) {
    return response.data
  } else if (response.status === 403) {
    throw new NotAllowedError(response.data.detail)
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else if (response.status === 409) {
    throw new AppError(response.data.detail)
  }

  throw new Error('Unexpected status code returned')
}

export const getBreadcrumbs = async ({ divisionId }) => {
  const response = await axios.get(`api/divisions/${divisionId}/breadcrumbs`)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export const getProjectActivitiesType = async () => {
  const response = await axios.get(`api/divisions/activities-type`)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export const getPartnerCompanyActivityType = async () => {
  const response = await axios.get(`api/divisions/partner-company-activities-type`)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export const getEditDivisionInfo = async (divisionId) => {
  const response = await axios.get(`api/divisions/${divisionId}/edit-division`)

  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}
